import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import VideoYoutube from "../components/videoYoutube"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import { Typography } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}))

const TravelPage = () => {
  const classes = useStyles()
  return (
    <Layout>
      <SEO title="Travel with me" />
      <Typography
        component="h2"
        variant="h5"
        align="center"
        style={{
          color: "white",
          position: "relative",
          top: "-5.75rem",
          marginBottom: "3rem",
        }}
      >
        See the word from a special perspective
      </Typography>
      <div className={classes.root}>
        <Typography component="h3" variant="h4" align="center">
          Check out my videos
        </Typography>
        <Grid container>
          <Grid item xs={12} className={classes.paper}>
            <Typography component="h4" variant="h5" align="center">
              Endless Greek blue - Greece 2019
            </Typography>
            <VideoYoutube
              videoSrcURL="https://www.youtube.com/embed/YhUW5pnTQPQ"
              videoTitle="Endless Greek blue - Greece 2019"
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.paper}>
            <Typography component="h4" variant="h6" align="center">
              The magic of Cyclades : The island of Paros and Naxos
            </Typography>
            <VideoYoutube
              videoSrcURL="https://www.youtube.com/embed/puuy2B0TIWA"
              videoTitle="The magic of Cyclades : The island of Paros and Naxos"
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.paper}>
            <Typography component="h4" variant="h6" align="center">
              Road Tripping in Australia [Perth to Alice Springs]
            </Typography>
            <VideoYoutube
              videoSrcURL="https://www.youtube.com/embed/BXnOWXbsADQ"
              videoTitle="Road Tripping in Australia [Perth to Alice Springs]"
            />
          </Grid>{" "}
          <Grid item xs={12} sm={6} className={classes.paper}>
            <Typography component="h4" variant="h6" align="center">
              Farm work Australia Pacific Seeds
            </Typography>
            <VideoYoutube
              videoSrcURL="https://www.youtube.com/embed/87BiUYiOd2U"
              videoTitle="Farm work Australia Pacific Seeds"
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.paper}>
            <Typography component="h4" variant="h6" align="center">
              Australia from the sky - Farmwork, 1st Part
            </Typography>
            <VideoYoutube
              videoSrcURL="https://www.youtube.com/embed/duWoTVLUcE0"
              videoTitle="Australia from the sky - Farmwork, 1st Part"
            />
          </Grid>
          <Grid item xs={12} className={classes.paper}>
            <Typography component="h4" variant="h6" align="center">
              Kimberley from the sky (Australia)
            </Typography>
            <VideoYoutube
              videoSrcURL="https://www.youtube.com/embed/IxPsPtsq-iQ"
              videoTitle="Kimberley from the sky (Australia)"
            />
          </Grid>
        </Grid>
      </div>
    </Layout>
  )
}

export default TravelPage
